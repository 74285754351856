var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "order-requiring-attention-page" },
    [
      !_vm.loaded
        ? _c("ct-centered-spinner", { staticStyle: { "margin-top": "33vh" } }, [
            _vm._v("\n    " + _vm._s(_vm.text.LOADING) + "\n  "),
          ])
        : [
            _c("h2", [_vm._v("\n      " + _vm._s(_vm.text.TITLE) + "\n    ")]),
            _c("p", { staticClass: "header" }, [
              _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.text.PAGE_HEADER_LINE_ONE) +
                    "\n      "
                ),
              ]),
              _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.text.PAGE_HEADER_LINE_TWO) +
                    "\n      "
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "card-container", attrs: { role: "tablist" } },
              [
                _vm._l(_vm.prioritizedOrderItems, function (item) {
                  return _c(
                    "b-card",
                    { key: item.id, attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          ref: "dropdown-" + item.id,
                          refInFor: true,
                          staticClass: "card-header",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "ora-button",
                              attrs: { block: "", variant: "light" },
                              on: {
                                click: function ($event) {
                                  return _vm.determineIfToggableForm(item)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "tab-container" }, [
                                _c(
                                  "div",
                                  { staticClass: "tab-header" },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "alert-triangle-icon",
                                      attrs: {
                                        type: "alert-triangle",
                                        height: "24",
                                        width: "24",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "ora-info-container" },
                                      [
                                        _c("div", { staticClass: "ora-info" }, [
                                          _c("span", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(item.company.name) +
                                                "\n                    "
                                            ),
                                          ]),
                                          _c("span", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(item.name) +
                                                "\n                    "
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "ora-sub-info" },
                                          [
                                            item.sub_registration
                                              ? _c("small", [
                                                  item.sub_registration
                                                    .local_jurisdiction
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item
                                                                .sub_registration
                                                                .local_jurisdiction
                                                                .name
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "arrow-icon-container" },
                                  [
                                    _vm.currentItemId === item.id
                                      ? _c("feather-icon", {
                                          attrs: {
                                            type: "chevron-up",
                                            height: "24",
                                            width: "24",
                                          },
                                        })
                                      : _c("feather-icon", {
                                          attrs: {
                                            type: "chevron-down",
                                            height: "24",
                                            width: "24",
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "dropdown-container",
                          attrs: {
                            id: item.id,
                            accordion: "filings",
                            role: "tabpanel",
                            visible: _vm.singleItem,
                          },
                        },
                        [
                          _vm.currentItemId === item.id || _vm.singleItem
                            ? _c("item-requiring-attention", {
                                attrs: { item: item },
                                on: { completed: _vm.completed },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _vm.noOrderItemsSoRedirect && _vm.loaded
                  ? _c("h4", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.text.NO_REMAINING_ORDER_ITEMS) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }