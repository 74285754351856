<template>
  <b-container class="order-requiring-attention-page">
    <ct-centered-spinner v-if="!loaded" style="margin-top: 33vh">
      {{ text.LOADING }}
    </ct-centered-spinner>

    <template v-else>
      <h2>
        {{ text.TITLE }}
      </h2>
      <p class="header">
        <span>
          {{ text.PAGE_HEADER_LINE_ONE }}
        </span>
        <span>
          {{ text.PAGE_HEADER_LINE_TWO }}
        </span>
      </p>
      <div class="card-container" role="tablist">
        <b-card
          v-for="item in prioritizedOrderItems"
          :key="item.id"
          no-body
        >
          <b-card-header
            :ref="'dropdown-' + item.id"
            header-tag="header"
            class="card-header"
            role="tab"
          >
            <b-button
              class="ora-button"
              block
              variant="light"
              @click="determineIfToggableForm(item)"
            >
              <div class="tab-container">
                <div class="tab-header">
                  <feather-icon
                    class="alert-triangle-icon"
                    type="alert-triangle"
                    height="24"
                    width="24"
                  />
                  <div class="ora-info-container">
                    <div class="ora-info">
                      <span>
                        {{ item.company.name }}
                      </span>
                      <span>
                        {{ item.name }}
                      </span>
                    </div>
                    <div class="ora-sub-info">
                      <small v-if="item.sub_registration">
                        <span v-if="item.sub_registration.local_jurisdiction">
                          {{ item.sub_registration.local_jurisdiction.name }}
                        </span>
                      </small>
                    </div>
                  </div>
                </div>
                <div class="arrow-icon-container">
                  <feather-icon
                    v-if="currentItemId === item.id"
                    type="chevron-up"
                    height="24"
                    width="24"
                  />
                  <feather-icon
                    v-else
                    type="chevron-down"
                    height="24"
                    width="24"
                  />
                </div>
              </div>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="item.id"
            class="dropdown-container"
            accordion="filings"
            role="tabpanel"
            :visible="singleItem"
          >
            <item-requiring-attention
              v-if="currentItemId === item.id || singleItem"
              :item="item"
              @completed="completed"
            />
          </b-collapse>
        </b-card>

        <h4 v-if="noOrderItemsSoRedirect && loaded">
          {{ text.NO_REMAINING_ORDER_ITEMS }}
        </h4>
      </div>
    </template>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex'
import { setTimeout } from "timers"
import http from "@/http"
import {
  createOrFindClientInteractionLog,
  logInteractionByTypeAndName,
} from '@/common/modules/clientInteractionLog'

export default {
  name: 'OrderItemsRequiringAttention',

  components: {
    FeatherIcon:            () => import('@/components/shared/FeatherIcon'),
    ItemRequiringAttention: () => import('@/components/ItemRequiringAttention'),
    CtCenteredSpinner:      () => import('@/components/shared/CtCenteredSpinner'),
  },

  data() {
    return {
      text: {
        TITLE: 'Order Items Requiring Attention',
        LOADING: 'Loading order items...',
        PAGE_HEADER_LINE_ONE: 'The following orders need additional information before processing.',
        PAGE_HEADER_LINE_TWO: 'Review and complete each form below. After submission, our expert filers will process the order.',
        NO_REMAINING_ORDER_ITEMS: 'No more services require attention. Redirecting to Home...',
      },
      orderItems: [],
      orderItemsInteractedWith: [],
      loaded: false,
      currentItemId: null,
      activeFieldReference: '',
      orderItemInteraction: null,
    }
  },

  computed: {
    noOrderItemsSoRedirect() {
      return this.orderItems.length === 0
    },
    prioritizedOrderItems() {
      const orderVerificationRequired = this.orderItems.filter(orderItem => orderItem.status === 'order-verification-required')
      const awaitingClientInput = this.orderItems.filter(orderItem => orderItem.status !== 'order-verification-required')

      return [ ...orderVerificationRequired, ...awaitingClientInput ]
    },
    singleItem() {
      return this.prioritizedOrderItems.length === 1
    },
  },

  async mounted() {
    this.loaded = false

    const promises = [
      this.loadAccount(),
      this.loadOrderItems(),
      this.setOrderItemInteraction(),
    ]

    await Promise.all(promises)

    this.forms = this.orderItems.reduce((acc, item) => Object.assign(acc, { [item.id]: null }), {})
    this.loaded = true

    if(this.singleItem) {
      this.currentItemId = this.prioritizedOrderItems[0].id
    }
  },

  methods: {
    ...mapActions('account', ['loadAccount']),
    ...mapActions('checkout', ['setupContext']),
    ...mapActions('verifyOrder', [
      'loadLatestOrderRequiringVerification',
    ]),
    async loadOrderItems() {
      const response = await http.get(`client/order_items/requiring_attention`)
      this.orderItems = response.data.result

      if (this.noOrderItemsSoRedirect) {
        await new Promise( r => setTimeout(r, 1000) )
        await this.$router.push({ name: 'dashpanel' })
      }
    },
    async completed(orderItem) {
      this.$root.$emit('bv::toggle::collapse', orderItem.id)

      await this.initializeValuesAndLogInteraction(orderItem, 'completed')
      await this.loadOrderItems()
    },
    async determineIfToggableForm(orderItem) {
      orderItem.status === 'order-verification-required' && orderItem.product.filing_name === 'register a company'
        ? await this.loadRegisterACompanyInVerifyOrderByOrderItemId(orderItem.name)
        : await this.toggleFormFor(orderItem)

      await this.initializeValuesAndLogInteraction(orderItem, 'selected')
    },
    toggleFormFor(orderItem) {
      this.$root.$emit('bv::toggle::collapse', orderItem.id)

      this.currentItemId = this.currentItemId === orderItem.id ? null : orderItem.id
    },
    async loadRegisterACompanyInVerifyOrderByOrderItemId(orderItemName) {
      await this.loadLatestOrderRequiringVerification({
        orderItemName: orderItemName,
      })

      this.$router.push({ name: 'verify-order' }).catch(() => {})
    },
    async initializeValuesAndLogInteraction(orderItem, action) {
      let type = ''
      const name = 'ora_document'
      const additionalParams = { document: orderItem.name }

      if (action === 'completed') {
        type = 'button-submit'
      }
      else {
        if (this.orderItemsInteractedWith.includes(orderItem.name)) return

        type = 'view'
        this.orderItemsInteractedWith.push(orderItem.name)
      }

      await this.logOrderItemInteraction(type, name, additionalParams)
    },
    async logOrderItemInteraction(type, name, additionalParams = null) {
      if (!this.orderItemInteraction) await this.setOrderItemInteraction()

      try {
        await logInteractionByTypeAndName({
          id: this.orderItemInteraction.id,
          type: type,
          name: name,
          subName: null,
          additionalParams: additionalParams,
        })
      } catch (_error) {
        // Suppressing Error
      }
    },
    async setOrderItemInteraction() {
      if (!this.orderItemInteraction) {
        try {
          this.orderItemInteraction = await createOrFindClientInteractionLog({
            category: 'page-interaction',
            subCategory: 'order-requiring-attention-page',
            companyId: null,
            incompleteLogFromToday: true,
          })
        } catch (_error) {
          // Suppressing Error
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$red1: #F45151;

.order-requiring-attention-page {
  max-width: 56em;

  h2 {
    margin-bottom: 0.5em;
  }
  .header {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.8em;

    span {
      padding-bottom: 0.4em;
    }
  }


  .card-container {
    margin-bottom: 2em;

    .card {
      margin-bottom: 1em;

      .card-header {
        padding: 0.8em;

        .ora-button {
          text-align: left;
          padding: 0 !important;

          .tab-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .tab-header {
              display: flex;
              flex-direction: row;

              .alert-triangle-icon {
                display: flex;
                align-items: center;
                padding-right: 0.8em;
                color: $red1;
              }
              .ora-info-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .ora-info {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }
                .ora-info > :nth-child(2) {
                  font-weight: $ct-ui-font-weight-7;
                }
              }
            }
            .arrow-icon-container {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
</style>
